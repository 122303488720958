var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"vetoInfo",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.vetoInfo,"sort-by":['id'],"hide-default-footer":"","no-data-text":_vm.$t('Veto.NoData'),"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.map",fn:function(ref){
var item = ref.item;
return [(item.pick_or_veto === 'pick')?_c('b',[_vm._v(" "+_vm._s(item.map)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.map)+" ")])]}},{key:"item.pick_or_veto",fn:function(ref){
var item = ref.item;
return [(item.pick_or_veto === 'pick')?_c('b',[_vm._v(" "+_vm._s(_vm.$t("Veto.VetoPick"))+" ")]):(item.pick_or_veto === 'ban')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Veto.VetoBan"))+" ")]):_vm._e()]}},{key:"item.team_name",fn:function(ref){
var item = ref.item;
return [(item.pick_or_veto === 'pick')?_c('b',[(item.team_name === 'Decider')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Veto.DeciderTeam"))+" ")]):_c('div',[_vm._v(" "+_vm._s(item.team_name)+" ")])]):_c('div',[_vm._v(" "+_vm._s(item.team_name)+" ")])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.side)?_c('v-icon',{class:[
          'v-data-table__expand-icon',
          { 'v-data-table__expand-icon--active': isExpanded } ],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v("$expand")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
        var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{key:item.id,staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.additionalHeaders,"hide-default-footer":"","dense":"","items":[item],"disable-sort":"","colspan":headers.length}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }