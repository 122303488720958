var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"statistics",attrs:{"fluid":""}},[(_vm.playerstats.length > 0)?_c('div',_vm._l((_vm.playerstats),function(playerMapStats,index){return _c('v-container',{key:playerMapStats[0].id},[_c('v-container',{staticClass:"mapinfo",attrs:{"fluid":""}},[(_vm.arrMapString[index] != null)?_c('div',{staticClass:"text-subtitle-2 mapInfo",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.arrMapString[index].score)+" - "+_vm._s(_vm.arrMapString[index].map)+" ")]):_vm._e(),(
            _vm.arrMapString[index] != null && _vm.arrMapString[index].start != null
          )?_c('div',{staticClass:"text-subtitle-2 mapInfo",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.arrMapString[index].start)+" ")]):_vm._e(),(
            _vm.arrMapString[index] != null && _vm.arrMapString[index].end != null
          )?_c('div',{staticClass:"text-subtitle-2 mapInfo",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.arrMapString[index].end)+" ")]):_vm._e(),(
            _vm.arrMapString[index] != null && _vm.arrMapString[index].demo != null
          )?_c('div',{staticClass:"text-subtitle-2 mapInfo",attrs:{"align":"center"}},[_c('v-btn',{attrs:{"small":"","color":"secondary","href":_vm.apiUrl + '/demo/' + _vm.arrMapString[index].demo}},[_vm._v(" "+_vm._s(_vm.$t("PlayerStats.Download"))+" ")])],1):_vm._e(),(
            _vm.arrMapString[index] != null && _vm.arrMapString[index].end == null
          )?_c('div',{staticClass:"text-subtitle-2 mapInfo",attrs:{"align":"left"}},[(!_vm.isFinished)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t("PlayerStats.RefreshData", { sec: _vm.countDownTimer }))+" ")]):_vm._e()]):_vm._e()]),_c('v-data-table',{ref:"PlayerStats",refInFor:true,staticClass:"elevation-1",attrs:{"item-key":"id","items-per-page":"12","loading":_vm.isLoading,"loading-text":_vm.$t('misc.LoadText'),"headers":_vm.headers,"items":playerMapStats,"sort-by":"kills","sort-desc":"","group-by":"Team","show-group-by":"","hide-default-footer":"","expanded":[],"show-expand":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: '/user/' + item.steam_id }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.Team",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.Team.slice(2))+" ")]}},{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-data-table',{key:item.id,staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.additionalHeaders,"hide-default-footer":"","dense":"","items":[item],"disable-sort":"","colspan":_vm.headers.length}})],1)]}}],null,true)})],1)}),1):_c('div',{attrs:{"align":"center"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("PlayerStats.NoStatFound"))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }